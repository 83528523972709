// Sass language features
@use "sass:map";

// 💁 Imports are ordered by least specific to most specific.
//    For example, helper classes should be further down in the cascade
//    than base styles, so that selectors of the same specificity will
//    resolve based on source order (last wins).

// NDS Tokens
@import "../dist/tokens/css/tokens.css";
@import "../dist/tokens/css/rgbColors.css";

// ⚠️ DEPRECATED ⚠️
// (see `src/deprecations.json`)
// These custom properties will be removed in a future release.
// For now, they map to the equivalent value from NDS Tokens
:root {
  --nds-black: var(--rgb-black); /* #333333 */
  --nds-grey: var(--rgb-grey); /* #4C4C4C */
  --nds-medium-grey: var(--rgb-mediumGrey); /* #8C8C8C */
  --nds-lightest-grey: var(--rgb-lightGrey); /* #D9D9D9 */
  --nds-smoke-grey: var(--rgb-smokeGrey); /* #F3F3F3 */
  --nds-red: var(--rgb-errorDark); /* #D93B3B */
  --nds-white: var(--rgb-white); /* #FFFFFF */
  --nds-font-family: var(--font-family-body);
  --nds-header-font: var(--font-family-heading);
  --subdued-20-opacity: var(--alpha-20);
  --subdued-10-opacity: var(--alpha-10);
  --subdued-5-opacity: var(--alpha-5);
}

// webfont
@import "fonts/fonts.css";

// icons
@import "icons/style.css";

// base styles
@import "base-styles/scss-utils"; // this must come first
@import "base-styles/reset";
@import "base-styles/defaults";
@import "base-styles/typography";
@import "base-styles/grid";
@import "base-styles/reach-ui"; // :FIXME: We may be able to remove this

// Helper classes
@import "helper-classes/position";
@import "helper-classes/spacing";
@import "helper-classes/font";
@import "helper-classes/background";
@import "helper-classes/border";
@import "helper-classes/forms";
@import "helper-classes/scroll";
@import "helper-classes/lists";

// Components
@import "Alert/";
@import "AutocompleteModal/";
@import "Avatar/";
@import "RadioButtons/";
@import "Input/";
@import "DateInput/";
@import "Button/";
@import "Combobox/";
@import "ContentCard/";
@import "CollapsibleCard/";
@import "ContextMenu/";
@import "IconButton/";
@import "MenuButton/";
@import "LoadingShim/";
@import "DisabledShim/";
@import "LoadingSkeleton/";
@import "Dialog/";
@import "Drawer/";
@import "Checkbox/";
@import "Dropdown/";
@import "DropdownTrigger/";
@import "Tooltip/";
@import "TruncatedAccount/";
@import "Row/";
@import "Pagination/";
@import "ProgressBar/";
@import "Select/";
@import "MultiSelect/";
@import "SeparatorList/";
@import "Sidebar/";
@import "Snackbar/";
@import "Popover/";
@import "Toggle/";
@import "TimelineEvent/";
@import "Tabs/";
@import "Tag/";
@import "Error/";
@import "FormSection/";
@import "FieldToken/";
@import "TokenInput/";
@import "Slider/";
