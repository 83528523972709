@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.woff?xck7wd") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="narmi-icon-"],
[class*=" narmi-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.narmi-icon-plane:before {
  content: "\ea4d";
}
.narmi-icon-filter-2:before {
  content: "\ea4a";
}
.narmi-icon-business:before {
  content: "\ea48";
}
.narmi-icon-profiles:before {
  content: "\ea49";
}
.narmi-icon-star-filled:before {
  content: "\ea3f";
}
.narmi-icon-car-outline:before {
  content: "\ea40";
}
.narmi-icon-dining:before {
  content: "\ea41";
}
.narmi-icon-fingerprint:before {
  content: "\ea42";
}
.narmi-icon-heart-pulse:before {
  content: "\ea43";
}
.narmi-icon-paw:before {
  content: "\ea44";
}
.narmi-icon-receipt:before {
  content: "\ea45";
}
.narmi-icon-self-care:before {
  content: "\ea46";
}
.narmi-icon-stroller:before {
  content: "\ea47";
}
.narmi-icon-security:before {
  content: "\ea3b";
  color: #4c4c4c;
}
.narmi-icon-narmi-fat-security:before {
  content: "\ea3b";
  color: #4c4c4c;
}
.narmi-icon-feed:before {
  content: "\ea3c";
  color: #4c4c4c;
}
.narmi-icon-narmi-feed:before {
  content: "\ea3c";
  color: #4c4c4c;
}
.narmi-icon-activity:before {
  content: "\e90d";
}
.narmi-icon-airplay:before {
  content: "\e90e";
}
.narmi-icon-alert-circle:before {
  content: "\e90f";
}
.narmi-icon-alert-octagon:before {
  content: "\e910";
}
.narmi-icon-alert-triangle:before {
  content: "\e911";
}
.narmi-icon-align-center:before {
  content: "\e912";
}
.narmi-icon-align-justify:before {
  content: "\e913";
}
.narmi-icon-align-left:before {
  content: "\e914";
}
.narmi-icon-align-right:before {
  content: "\e915";
}
.narmi-icon-anchor:before {
  content: "\e916";
}
.narmi-icon-aperture:before {
  content: "\e917";
}
.narmi-icon-archive:before {
  content: "\e918";
}
.narmi-icon-arrow-down:before {
  content: "\e919";
}
.narmi-icon-arrow-down-circle:before {
  content: "\e91a";
}
.narmi-icon-arrow-down-left:before {
  content: "\e91b";
}
.narmi-icon-arrow-down-right:before {
  content: "\e91c";
}
.narmi-icon-arrow-left:before {
  content: "\e91d";
}
.narmi-icon-arrow-left-circle:before {
  content: "\e91e";
}
.narmi-icon-arrow-right:before {
  content: "\e91f";
}
.narmi-icon-arrow-right-circle:before {
  content: "\e920";
}
.narmi-icon-arrow-up:before {
  content: "\e924";
}
.narmi-icon-arrow-up-circle:before {
  content: "\e925";
}
.narmi-icon-arrow-up-left:before {
  content: "\e926";
}
.narmi-icon-arrow-up-right:before {
  content: "\e927";
}
.narmi-icon-arrow-up-down:before {
  content: "\e900";
}
.narmi-icon-at-sign:before {
  content: "\e928";
}
.narmi-icon-award:before {
  content: "\e929";
}
.narmi-icon-bar-chart:before {
  content: "\e92a";
}
.narmi-icon-bar-chart-2:before {
  content: "\e92b";
}
.narmi-icon-battery:before {
  content: "\e92c";
}
.narmi-icon-battery-charging:before {
  content: "\e92d";
}
.narmi-icon-bell:before {
  content: "\e92e";
}
.narmi-icon-bell-off:before {
  content: "\e92f";
}
.narmi-icon-bluetooth:before {
  content: "\e930";
}
.narmi-icon-bold:before {
  content: "\e931";
}
.narmi-icon-book:before {
  content: "\e932";
}
.narmi-icon-book-open:before {
  content: "\e933";
}
.narmi-icon-bookmark:before {
  content: "\e934";
}
.narmi-icon-box:before {
  content: "\e935";
}
.narmi-icon-briefcase:before {
  content: "\e936";
}
.narmi-icon-calendar:before {
  content: "\e937";
}
.narmi-icon-camera:before {
  content: "\e938";
}
.narmi-icon-camera-off:before {
  content: "\e939";
}
.narmi-icon-cast:before {
  content: "\e93a";
}
.narmi-icon-check:before {
  content: "\e93b";
}
.narmi-icon-check-circle:before {
  content: "\e93c";
}
.narmi-icon-check-square:before {
  content: "\e93d";
}
.narmi-icon-chevron-down:before {
  content: "\e93e";
}
.narmi-icon-chevron-left:before {
  content: "\e940";
}
.narmi-icon-chevron-right:before {
  content: "\e941";
}
.narmi-icon-chevron-up:before {
  content: "\e942";
}
.narmi-icon-chevrons-down:before {
  content: "\e943";
}
.narmi-icon-chevrons-left:before {
  content: "\e944";
}
.narmi-icon-chevrons-right:before {
  content: "\e945";
}
.narmi-icon-chevrons-up:before {
  content: "\e946";
}
.narmi-icon-chrome:before {
  content: "\e947";
}
.narmi-icon-circle:before {
  content: "\e948";
}
.narmi-icon-clipboard:before {
  content: "\e949";
}
.narmi-icon-clock:before {
  content: "\e94a";
}
.narmi-icon-cloud:before {
  content: "\e94b";
}
.narmi-icon-cloud-drizzle:before {
  content: "\e94c";
}
.narmi-icon-cloud-lightning:before {
  content: "\e94d";
}
.narmi-icon-cloud-off:before {
  content: "\e94e";
}
.narmi-icon-cloud-rain:before {
  content: "\e94f";
}
.narmi-icon-cloud-snow:before {
  content: "\e950";
}
.narmi-icon-code:before {
  content: "\e951";
}
.narmi-icon-codepen:before {
  content: "\e952";
}
.narmi-icon-codesandbox:before {
  content: "\e953";
}
.narmi-icon-coffee:before {
  content: "\e954";
}
.narmi-icon-columns:before {
  content: "\e955";
}
.narmi-icon-command:before {
  content: "\e956";
}
.narmi-icon-compass:before {
  content: "\e957";
}
.narmi-icon-copy:before {
  content: "\e958";
}
.narmi-icon-corner-down-left:before {
  content: "\e959";
}
.narmi-icon-corner-down-right:before {
  content: "\e95a";
}
.narmi-icon-corner-left-down:before {
  content: "\e95b";
}
.narmi-icon-corner-left-up:before {
  content: "\e95c";
}
.narmi-icon-corner-right-down:before {
  content: "\e95d";
}
.narmi-icon-corner-right-up:before {
  content: "\e95e";
}
.narmi-icon-corner-up-left:before {
  content: "\e95f";
}
.narmi-icon-corner-up-right:before {
  content: "\e960";
}
.narmi-icon-cpu:before {
  content: "\e961";
}
.narmi-icon-credit-card1:before {
  content: "\e962";
}
.narmi-icon-crop:before {
  content: "\e963";
}
.narmi-icon-crosshair:before {
  content: "\e964";
}
.narmi-icon-database:before {
  content: "\e965";
}
.narmi-icon-delete:before {
  content: "\e966";
}
.narmi-icon-disc:before {
  content: "\e967";
}
.narmi-icon-dollar-sign:before {
  content: "\e968";
}
.narmi-icon-download:before {
  content: "\e969";
}
.narmi-icon-download-cloud:before {
  content: "\e96a";
}
.narmi-icon-droplet:before {
  content: "\e96b";
}
.narmi-icon-edit:before {
  content: "\e96c";
}
.narmi-icon-edit-2:before {
  content: "\e96d";
}
.narmi-icon-edit-3:before {
  content: "\e96e";
}
.narmi-icon-external-link:before {
  content: "\e96f";
}
.narmi-icon-eye:before {
  content: "\e970";
}
.narmi-icon-eye-off:before {
  content: "\e971";
}
.narmi-icon-facebook:before {
  content: "\e972";
}
.narmi-icon-fast-forward:before {
  content: "\e973";
}
.narmi-icon-feather:before {
  content: "\e974";
}
.narmi-icon-figma:before {
  content: "\e975";
}
.narmi-icon-file:before {
  content: "\e976";
}
.narmi-icon-file-minus:before {
  content: "\e977";
}
.narmi-icon-file-plus:before {
  content: "\e978";
}
.narmi-icon-file-text1:before {
  content: "\e979";
}
.narmi-icon-film:before {
  content: "\e97a";
}
.narmi-icon-filter:before {
  content: "\e97b";
}
.narmi-icon-flag:before {
  content: "\e97c";
}
.narmi-icon-folder:before {
  content: "\e97d";
}
.narmi-icon-folder-minus:before {
  content: "\e97e";
}
.narmi-icon-folder-plus:before {
  content: "\e97f";
}
.narmi-icon-framer:before {
  content: "\e980";
}
.narmi-icon-frown:before {
  content: "\e981";
}
.narmi-icon-gift:before {
  content: "\e982";
}
.narmi-icon-git-branch:before {
  content: "\e983";
}
.narmi-icon-git-commit:before {
  content: "\e984";
}
.narmi-icon-git-merge:before {
  content: "\e985";
}
.narmi-icon-git-pull-request:before {
  content: "\e986";
}
.narmi-icon-github:before {
  content: "\e987";
}
.narmi-icon-gitlab:before {
  content: "\e988";
}
.narmi-icon-globe:before {
  content: "\e989";
}
.narmi-icon-grid:before {
  content: "\e98a";
}
.narmi-icon-hard-drive:before {
  content: "\e98b";
}
.narmi-icon-hash:before {
  content: "\e98c";
}
.narmi-icon-headphones:before {
  content: "\e98d";
}
.narmi-icon-heart:before {
  content: "\e98e";
}
.narmi-icon-help-circle:before {
  content: "\e98f";
}
.narmi-icon-hexagon:before {
  content: "\e990";
}
.narmi-icon-home:before {
  content: "\e991";
}
.narmi-icon-image:before {
  content: "\e992";
}
.narmi-icon-inbox:before {
  content: "\e993";
}
.narmi-icon-info:before {
  content: "\e994";
}
.narmi-icon-instagram:before {
  content: "\e995";
}
.narmi-icon-italic:before {
  content: "\e996";
}
.narmi-icon-key:before {
  content: "\e997";
}
.narmi-icon-layers:before {
  content: "\e998";
}
.narmi-icon-layout:before {
  content: "\e999";
}
.narmi-icon-life-buoy:before {
  content: "\e99a";
}
.narmi-icon-link:before {
  content: "\e99b";
}
.narmi-icon-link-2:before {
  content: "\e99c";
}
.narmi-icon-linkedin:before {
  content: "\e99d";
}
.narmi-icon-list:before {
  content: "\e99e";
}
.narmi-icon-loader:before {
  content: "\e99f";
}
.narmi-icon-lock:before {
  content: "\e9a0";
}
.narmi-icon-log-in:before {
  content: "\e9a1";
}
.narmi-icon-log-out:before {
  content: "\e9a2";
}
.narmi-icon-mail:before {
  content: "\e9a3";
}
.narmi-icon-map:before {
  content: "\e9a4";
}
.narmi-icon-map-pin:before {
  content: "\e9a5";
}
.narmi-icon-maximize:before {
  content: "\e9a6";
}
.narmi-icon-maximize-2:before {
  content: "\e9a7";
}
.narmi-icon-meh:before {
  content: "\e9a8";
}
.narmi-icon-menu:before {
  content: "\e9a9";
}
.narmi-icon-message-circle:before {
  content: "\e9aa";
}
.narmi-icon-message-square:before {
  content: "\e9ab";
}
.narmi-icon-mic:before {
  content: "\e9ac";
}
.narmi-icon-mic-off:before {
  content: "\e9ad";
}
.narmi-icon-minimize:before {
  content: "\e9ae";
}
.narmi-icon-minimize-2:before {
  content: "\e9af";
}
.narmi-icon-minus:before {
  content: "\e9b0";
}
.narmi-icon-minus-circle:before {
  content: "\e9b1";
}
.narmi-icon-minus-square:before {
  content: "\e9b2";
}
.narmi-icon-monitor:before {
  content: "\e9b3";
}
.narmi-icon-moon:before {
  content: "\e9b4";
}
.narmi-icon-more-horizontal:before {
  content: "\e9b5";
}
.narmi-icon-more-vertical:before {
  content: "\e9b6";
}
.narmi-icon-mouse-pointer:before {
  content: "\e9b7";
}
.narmi-icon-move:before {
  content: "\e9b8";
}
.narmi-icon-music:before {
  content: "\e9b9";
}
.narmi-icon-navigation:before {
  content: "\e9ba";
}
.narmi-icon-navigation-2:before {
  content: "\e9bb";
}
.narmi-icon-octagon:before {
  content: "\e9bc";
}
.narmi-icon-package:before {
  content: "\e9bd";
}
.narmi-icon-paperclip:before {
  content: "\e9be";
}
.narmi-icon-pause:before {
  content: "\e9bf";
}
.narmi-icon-pause-circle:before {
  content: "\e9c0";
}
.narmi-icon-pen-tool:before {
  content: "\e9c1";
}
.narmi-icon-percent:before {
  content: "\e9c2";
}
.narmi-icon-phone:before {
  content: "\e9c3";
}
.narmi-icon-phone-call:before {
  content: "\e9c4";
}
.narmi-icon-phone-forwarded:before {
  content: "\e9c5";
}
.narmi-icon-phone-incoming:before {
  content: "\e9c6";
}
.narmi-icon-phone-missed:before {
  content: "\e9c7";
}
.narmi-icon-phone-off:before {
  content: "\e9c8";
}
.narmi-icon-phone-outgoing:before {
  content: "\e9c9";
}
.narmi-icon-pie-chart:before {
  content: "\e9ca";
}
.narmi-icon-play:before {
  content: "\e9cb";
}
.narmi-icon-play-circle:before {
  content: "\e9cc";
}
.narmi-icon-plus:before {
  content: "\e9cd";
}
.narmi-icon-plus-circle:before {
  content: "\e9ce";
}
.narmi-icon-plus-square:before {
  content: "\e9cf";
}
.narmi-icon-pocket:before {
  content: "\e9d0";
}
.narmi-icon-power:before {
  content: "\e9d1";
}
.narmi-icon-printer:before {
  content: "\e9d2";
}
.narmi-icon-radio:before {
  content: "\e9d3";
}
.narmi-icon-refresh-ccw:before {
  content: "\e9d4";
}
.narmi-icon-refresh-cw:before {
  content: "\e9d5";
}
.narmi-icon-repeat:before {
  content: "\e9d6";
}
.narmi-icon-rewind:before {
  content: "\e9d7";
}
.narmi-icon-rotate-ccw:before {
  content: "\e9d8";
}
.narmi-icon-rotate-cw:before {
  content: "\e9d9";
}
.narmi-icon-rss:before {
  content: "\e9da";
}
.narmi-icon-save:before {
  content: "\e9db";
}
.narmi-icon-scissors:before {
  content: "\e9dc";
}
.narmi-icon-search:before {
  content: "\e9dd";
}
.narmi-icon-send:before {
  content: "\e9de";
}
.narmi-icon-server:before {
  content: "\e9df";
}
.narmi-icon-settings:before {
  content: "\e9e0";
}
.narmi-icon-share:before {
  content: "\e9e1";
}
.narmi-icon-share-2:before {
  content: "\e9e2";
}
.narmi-icon-shield:before {
  content: "\e9e3";
}
.narmi-icon-shield-off:before {
  content: "\e9e4";
}
.narmi-icon-shopping-bag:before {
  content: "\e9e5";
}
.narmi-icon-shopping-cart:before {
  content: "\e9e6";
}
.narmi-icon-shuffle:before {
  content: "\e9e7";
}
.narmi-icon-sidebar:before {
  content: "\e9e8";
}
.narmi-icon-skip-back:before {
  content: "\e9e9";
}
.narmi-icon-skip-forward:before {
  content: "\e9ea";
}
.narmi-icon-slack:before {
  content: "\e9eb";
}
.narmi-icon-slash:before {
  content: "\e9ec";
}
.narmi-icon-sliders:before {
  content: "\e9ed";
}
.narmi-icon-smartphone:before {
  content: "\e9ee";
}
.narmi-icon-smile:before {
  content: "\e9ef";
}
.narmi-icon-speaker:before {
  content: "\e9f0";
}
.narmi-icon-square:before {
  content: "\e9f1";
}
.narmi-icon-star:before {
  content: "\e9f2";
}
.narmi-icon-stop-circle:before {
  content: "\e9f3";
}
.narmi-icon-sun:before {
  content: "\e9f4";
}
.narmi-icon-sunrise:before {
  content: "\e9f5";
}
.narmi-icon-sunset:before {
  content: "\e9f6";
}
.narmi-icon-tablet:before {
  content: "\e9f7";
}
.narmi-icon-tag:before {
  content: "\e9f8";
}
.narmi-icon-target:before {
  content: "\e9f9";
}
.narmi-icon-terminal:before {
  content: "\e9fa";
}
.narmi-icon-thermometer:before {
  content: "\e9fb";
}
.narmi-icon-thumbs-down:before {
  content: "\e9fc";
}
.narmi-icon-thumbs-up:before {
  content: "\e9fd";
}
.narmi-icon-toggle-left:before {
  content: "\e9fe";
}
.narmi-icon-toggle-right:before {
  content: "\e9ff";
}
.narmi-icon-tool:before {
  content: "\ea00";
}
.narmi-icon-trash:before {
  content: "\ea01";
}
.narmi-icon-trash-2:before {
  content: "\ea02";
}
.narmi-icon-trello:before {
  content: "\ea03";
}
.narmi-icon-trending-down:before {
  content: "\ea04";
}
.narmi-icon-trending-up:before {
  content: "\ea05";
}
.narmi-icon-triangle:before {
  content: "\ea06";
}
.narmi-icon-truck:before {
  content: "\ea07";
}
.narmi-icon-tv:before {
  content: "\ea08";
}
.narmi-icon-twitch:before {
  content: "\ea09";
}
.narmi-icon-twitter:before {
  content: "\ea0a";
}
.narmi-icon-type:before {
  content: "\ea0b";
}
.narmi-icon-umbrella:before {
  content: "\ea0c";
}
.narmi-icon-underline:before {
  content: "\ea0d";
}
.narmi-icon-unlock:before {
  content: "\ea0e";
}
.narmi-icon-upload:before {
  content: "\ea0f";
}
.narmi-icon-upload-cloud:before {
  content: "\ea10";
}
.narmi-icon-user:before {
  content: "\ea11";
}
.narmi-icon-user-check:before {
  content: "\ea12";
}
.narmi-icon-user-minus:before {
  content: "\ea13";
}
.narmi-icon-user-plus:before {
  content: "\ea14";
}
.narmi-icon-user-x:before {
  content: "\ea15";
}
.narmi-icon-users:before {
  content: "\ea16";
}
.narmi-icon-video:before {
  content: "\ea17";
}
.narmi-icon-video-off:before {
  content: "\ea18";
}
.narmi-icon-voicemail:before {
  content: "\ea19";
}
.narmi-icon-volume:before {
  content: "\ea1a";
}
.narmi-icon-volume-1:before {
  content: "\ea1b";
}
.narmi-icon-volume-2:before {
  content: "\ea1c";
}
.narmi-icon-volume-x:before {
  content: "\ea1d";
}
.narmi-icon-watch:before {
  content: "\ea1e";
}
.narmi-icon-wifi:before {
  content: "\ea1f";
}
.narmi-icon-wifi-off:before {
  content: "\ea20";
}
.narmi-icon-wind:before {
  content: "\ea21";
}
.narmi-icon-x:before {
  content: "\ea22";
}
.narmi-icon-x-circle:before {
  content: "\ea23";
}
.narmi-icon-x-octagon:before {
  content: "\ea24";
}
.narmi-icon-x-square:before {
  content: "\ea25";
}
.narmi-icon-youtube:before {
  content: "\ea26";
}
.narmi-icon-zap:before {
  content: "\ea27";
}
.narmi-icon-zap-off:before {
  content: "\ea28";
}
.narmi-icon-zoom-in:before {
  content: "\ea29";
}
.narmi-icon-zoom-out:before {
  content: "\ea2a";
}
.narmi-icon-student:before {
  content: "\ea39";
}
.narmi-icon-hand-spock:before {
  content: "\ea3a";
}
.narmi-icon-solid-circle:before {
  content: "\ea2f";
}
.narmi-icon-GroupOutlined:before {
  content: "\ea2b";
}
.narmi-icon-LocalAtm:before {
  content: "\ea2c";
}
.narmi-icon-MonetizationOnOutlined:before {
  content: "\ea2d";
}
.narmi-icon-SyncAlt:before {
  content: "\ea2e";
}
.narmi-icon-p2p:before {
  content: "\e908";
}
.narmi-icon-check-endorsed:before {
  content: "\e902";
}
.narmi-icon-piggy-bank:before {
  content: "\e905";
}
.narmi-icon-venmo:before {
  content: "\e90a";
}
.narmi-icon-folder-open:before {
  content: "\e901";
}
.narmi-icon-group:before {
  content: "\e903";
}
.narmi-icon-settings-gear:before {
  content: "\e907";
}
.narmi-icon-admin-user:before {
  content: "\ea35";
}
.narmi-icon-transfer-arrows:before {
  content: "\ea31";
}
.narmi-icon-narmi-transfer:before {
  content: "\ea31";
}
.narmi-icon-blob:before {
  content: "\ea30";
}
.narmi-icon-bill:before {
  content: "\e90c";
}
.narmi-icon-credit-card:before {
  content: "\e93f";
}
.narmi-icon-bank:before {
  content: "\ea4c";
}
.narmi-icon-loan:before {
  content: "\ea4b";
}
.narmi-icon-logo-remitly:before {
  content: "\ea3e";
}
.narmi-icon-zelle-outline:before {
  content: "\ea3d";
}
.narmi-icon-question:before {
  content: "\ea36";
}
.narmi-icon-signal:before {
  content: "\ea37";
}
.narmi-icon-car:before {
  content: "\ea38";
}
.narmi-icon-check-front:before {
  content: "\e906";
}
.narmi-icon-dollar-bill-line:before {
  content: "\ea32";
}
.narmi-icon-wires:before {
  content: "\ea33";
}
.narmi-icon-m2m:before {
  content: "\ea34";
}
.narmi-icon-venmo-logo:before {
  content: "\e90b";
  color: #3d95ce;
}
.narmi-icon-face-id:before {
  content: "\e909";
}
.narmi-icon-library:before {
  content: "\e921";
}
.narmi-icon-profile:before {
  content: "\e923";
}
.narmi-icon-office:before {
  content: "\e904";
}
.narmi-icon-file-text:before {
  content: "\e922";
}
