.nds-tokenInput {
  .nds-input-box {
    flex-wrap: wrap;
  }

  // We use the floating label flex column height to create space above and
  // below the input instead of input padding.
  // This allows us to center the input with tokens on a single line,
  // and creates vertical space between the tokens and the input when tokens wrap.
  .nds-input-column {
    justify-content: center;
    height: 32px;

    // Allow the input to shrink down its width to a minimum of 8 visible chars.
    // This prevents the input from prematurely wrapping to the second line.
    flex-basis: min-content;
    min-width: 8ch;
  }
  input {
    padding: 0 !important;
  }

  legend,
  .nds-input-column label {
    @extend %a11y-visually-hide;
  }
}

.nds-tokenInput-tokens {
  display: flex;
  gap: var(--space-s);
  flex-wrap: wrap;
}
