/**
 * Do not edit directly
 * Generated on Wed, 08 Jan 2025 17:06:04 GMT
 */

:root {
  --elevation-high: 0 2px 12px 0 rgba(80, 80, 80, 0.2);
  --elevation-middle: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  --elevation-low: 0 2px 12px 0 rgba(80, 80, 80, 0.05);
  --space-xxl: 60px;
  --space-xl: 40px;
  --space-l: 20px;
  --space-m: 16px;
  --space-s: 12px;
  --space-xs: 8px;
  --space-xxs: 4px;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-normal: 400;
  --font-weight-thin: 200;
  --font-size-heading3: 1.5rem;
  --font-size-heading2: 1.75rem;
  --font-size-heading1: 2rem;
  --font-size-heading0: 2.5rem;
  --font-size-l: 1.25rem;
  --font-size-m: 1rem;
  --font-size-s: 0.875rem;
  --font-size-xs: 0.75rem;
  --font-size-xxs: 0.625rem;
  --font-lineHeight-bigText: 1.1;
  --font-lineHeight-bodyText: 1.25;
  --font-lineHeight-smallText: 1.6;
  --font-family-body: Mulish, Helvetica, sans-serif;
  --font-family-heading: 'Narmi Matiere', serif;
  --font-color-secondary: #6c6c6c;
  --color-errorLight: #fdf1f1;
  --color-errorDark: #d93b3b;
  --color-infoLight: #e8eef1;
  --color-infoDark: #195274;
  --color-warnLight: #fef8e3;
  --color-warnDark: #eac348;
  --color-successLight: #e3fae7;
  --color-successDark: #37b374;
  --color-white: #ffffff;
  --color-lightGrey: #d9d9d9;
  --color-mediumGrey: #8c8c8c;
  --color-grey: #4c4c4c;
  --color-black: #333333;
  --color-sand800: #fcf0e9;
  --color-sand700: #f4e3d6;
  --color-sand600: #eed6c3;
  --color-sand500: #dcc1a2;
  --color-sand400: #c4a08a;
  --color-sand300: #947564;
  --color-sand200: #684d41;
  --color-sand100: #423329;
  --color-cactus800: #e5f1dc;
  --color-cactus700: #cce4ba;
  --color-cactus600: #b3d79a;
  --color-cactus500: #99ca7a;
  --color-cactus400: #7fbc5b;
  --color-cactus300: #63964b;
  --color-cactus200: #4b7137;
  --color-cactus100: #324c23;
  --color-pistachio800: #f7fcf3;
  --color-pistachio700: #e9f8de;
  --color-pistachio600: #daf4c8;
  --color-pistachio500: #ccf0b4;
  --color-pistachio400: #b5e995;
  --color-pistachio300: #90bb78;
  --color-pistachio200: #6b8c5a;
  --color-pistachio100: #485e3d;
  --color-azul800: #c9d8f2;
  --color-azul700: #9cb1de;
  --color-azul600: #6b89c7;
  --color-azul500: #3664b5;
  --color-azul400: #003ea0;
  --color-azul300: #002c82;
  --color-azul200: #00205e;
  --color-azul100: #001340;
  --color-cove800: #d4edff;
  --color-cove700: #a6cff0;
  --color-cove600: #66a2d6;
  --color-cove500: #237cc3;
  --color-cove400: #005cb2;
  --color-cove300: #094895;
  --color-cove200: #00376a;
  --color-cove100: #002547;
  --color-pine800: #cdd6d3;
  --color-pine700: #708981;
  --color-pine600: #708981;
  --color-pine500: #44655b;
  --color-pine400: #1a4338;
  --color-pine300: #16362c;
  --color-pine200: #062821;
  --color-pine100: #041a14;
  --color-moss800: #cdded9;
  --color-moss700: #9dc0b6;
  --color-moss600: #6ea193;
  --color-moss500: #3e8373;
  --color-moss400: #006553;
  --color-moss300: #005242;
  --color-moss200: #0f3c32;
  --color-moss100: #002921;
  --bgColor-scrimDark: rgba(80, 80, 80, 0.7);
  --bgColor-scrimLight: rgba(255, 255, 255, 0.85);
  --bgColor-snowGrey: #fbfbfb;
  --bgColor-smokeGrey: #f3f3f3;
  --bgColor-neutralGrey: #f9f9f9;
  --bgColor-cloudGrey: #e9e9e9;
  --bgColor-blueGrey: #f4f6fa;
  --alpha-20: 0.2;
  --alpha-10: 0.1;
  --alpha-5: 0.05;
  --border-size-m: 2px;
  --border-size-s: 1px;
  --border-radius-l: 20px;
  --border-radius-m: 8px;
  --border-radius-s: 4px;
  --elevation-default: var(--elevation-middle);
  --space-default: var(--space-l);
  --font-weight-default: var(--font-weight-normal);
  --font-size-default: var(--font-size-m);
  --font-lineHeight-default: var(--font-lineHeight-bodyText);
  --font-family-default: var(--font-family-body);
  --font-color-hint: var(--color-lightGrey);
  --font-color-primary: var(--color-grey);
  --font-color-heading: var(--color-black);
  --color-sand: var(--color-sand500);
  --color-cactus: var(--color-cactus400);
  --color-pistachio: var(--color-pistachio400);
  --color-azul: var(--color-azul400);
  --color-cove: var(--color-cove400);
  --color-pine: var(--color-pine400);
  --color-moss: var(--color-moss400);
  --bgColor-white: var(--color-white);
  --border-size-default: var(--border-size-s);
  --border-radius-default: var(--border-radius-s);
  --border-color-light: var(--color-lightGrey);
  --theme-tertiary: var(--color-cactus);
  --theme-secondary: var(--color-moss);
  --theme-primary: var(--color-pine);
  --border-color-default: var(--border-color-light);
  --theme-link: var(--theme-secondary);
  --border-color-primary: var(--theme-primary);
}
