.nds-fieldToken {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  height: rem(25px);
  color: var(--theme-primary);
  background: rgba(var(--theme-rgb-primary), var(--alpha-20));

  // Prevent forced overflow.
  // Truncate after 300px - no token should be wider than a small mobile viewport
  max-width: rem(300px); // this constraint should scale relative to user font

  &--disabled {
    background: rgba(var(--theme-rgb-primary), var(--alpha-10));
  }

  // Renders an icon with a small glyph inside a 16x16 click target
  .narmi-icon-x {
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: var(--space-s);
    width: var(--space-s);
    &:before {
      font-weight: var(--font-weight-semibold);
      font-size: rem(10px);
    }
  }
}
