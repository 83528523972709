/**
* MultiSelect styles
*
* Base styles mostly copied from `Select`.
* We can unify these styles later, but for now,
* we're leaving them separate to make iteration easier.
*/

.nds-multiselect-list {
  max-height: 50vh;
  overflow-y: auto;
  z-index: 4;

  &:focus {
    outline: none;
  }

  &--active--top,
  &--active--bottom {
    border: 1px solid var(--theme-primary);
  }

  &--active--top {
    border-bottom: 1px solid var(--border-color-default);
  }

  &--active--bottom {
    border-top: 1px solid var(--border-color-default);
  }
}

.nds-multiselect-item {
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  min-height: var(--space-xl);
  &:hover,
  &--highlighted {
    background: RGBA(var(--theme-rgb-primary), var(--alpha-5));
  }
}

.nds-multiselect-tokensList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xs) var(--space-s);
  margin-right: var(--space-s); // make room for the right caret
}
