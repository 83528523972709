.nds-tooltip {
  border-radius: var(--border-radius-default);
  padding: var(--space-s);
  font-size: var(--font-size-s);
  background-color: var(--color-black);
  color: var(--color-white);
  text-align: center;
  z-index: 999;

  // The arrow SVG path
  path {
    fill: var(--color-black);
  }
}
