.nds-dropdownTrigger {
  position: relative;
  min-height: rem(48px);
  display: flex;
  align-items: center;
}
.nds-dropdownTrigger-button {
  min-height: rem(48px);
  width: 100%;
  text-align: left;
  border: 1px solid var(--border-color-default) !important;
  background-color: var(--color-white);

  &--isActive,
  &:focus {
    outline: none;
    border-color: var(--theme-primary) !important;
  }

  &--hasError {
    border-color: var(--color-errorDark) !important;
  }

  &--disabled {
    user-select: none;
    pointer-events: none;
    color: var(--color-mediumGrey);
    background-color: var(--bgColor-smokeGrey);
  }
}

.nds-dropdownTrigger-label {
  color: var(--color-mediumGrey);
  font-weight: var(--font-weight-default);
  text-rendering: geometricPrecision; // match input placeholder weight
}

.nds-dropdownTrigger-tokens {
  display: inline-flex;
  flex-wrap: wrap;
  gap: var(--space-xs);
}

.nds-dropdownTrigger-value {
  color: var(--font-color-primary);
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: rem(24px);
}

// float the label to top like a text input
// when there's a value to display
.nds-dropdownTrigger-button--hasValue {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & .nds-dropdownTrigger-label {
    font-size: var(--font-size-xs);
  }

  &.nds-dropdownTrigger-button--hasError .nds-dropdownTrigger-label {
    color: var(--color-errorDark);
  }
}

.nds-dropdownTrigger-chevron {
  position: absolute;
  right: var(--space-s);
  top: 50%;
  transform: translateY(-50%);

  // only overlap popovers if in an open state
  &.narmi-icon-chevron-up {
    z-index: 4;
  }
}
