.nds-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-primary);

  ul {
    list-style-type: none;
  }
  ul,
  li {
    text-indent: 0;
    margin: 0;
    padding: 0;
  }
}

.nds-pagination-page,
.nds-pagination-ellipsis {
  user-select: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: rem(28px);
  min-width: rem(28px);
  border-radius: rem(28px);
  padding: 0 var(--space-xs);
}

.nds-pagination-page {
  cursor: pointer;
  color: var(--font-color-primary);

  &:hover {
    background-color: RGBA(var(--theme-rgb-primary), var(--alpha-20));
  }

  &--selected {
    background-color: var(--theme-primary) !important;
    color: var(--color-white);
  }

  &--disabled {
    pointer-events: none;
    cursor: none;
    color: var(--font-color-hint);
    background-color: transparent !important;
  }
}

.nds-pagination-ellipsis {
  transform: translateY(rem(-5px));
  color: var(--fontColor-tertiary);
  font-weight: var(--fontWeight-semibold);
}
