.nds-toggle-button-container {
  width: 48px;
  line-height: 13px;

  .nds-toggle {
    position: relative;
    width: 48px;
    height: 24px;
    border-radius: 24px;
    border: 0;
    background-color: var(--color-lightGrey);
    will-change: background-color, left, width;
    transition:
      background-color 200ms ease,
      left 200ms ease,
      width 200ms ease;

    &--loading {
      width: 24px;
      border-radius: 50%;
      left: 12px;

      .nds-toggle-indicator {
        left: 4px;
      }

      .nds-toggle-loading-overlay {
        opacity: 0.8;
        display: block;
        will-change: opacity;
        transition: opacity 200ms ease;
        transition-delay: 200ms;
      }
    }
  }

  &--active {
    .nds-toggle {
      background-color: var(--theme-primary);

      .nds-toggle-indicator {
        left: 28px;
      }
      &.nds-toggle--loading {
        .nds-toggle-indicator {
          left: 4px;
        }
      }
    }
  }
}

.nds-toggle-indicator {
  position: absolute;
  top: 50%;
  left: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--color-white);
  transform: translateY(-50%);
  will-change: left;
  transition: left 200ms ease;
}

/**
 * Hide the "on" : "off" button text visually, but
 * still visible to screen readers and other assistive technology
 */
.nds-toggle-buttonText {
  @extend %a11y-visually-hide;
}

.nds-toggle-loading-overlay {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  top: 0;
  border-radius: 24px;
  opacity: 0;
}
