.drawerContainer {
  --transition-speed-fast: 0.4s;
  --transition-speed-medium: 0.7s;
  --transition-speed-slow: 1s;
}

.drawer {
  background: var(--bgColor-neutralGrey);
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  transition: transform var(--transition-speed-fast) ease;
  border: var(--border-size-s);
  border-color: var(--border-color-light);
  z-index: 3;
}
.drawer-content {
  overflow: auto;
  flex-grow: 1;
}
.drawer-footer {
  flex-basis: auto;
  flex-grow: 0;
  height: initial;
}
.drawer--left {
  top: 0;
  left: 0;
  transform: translateX(-100%);
}
.drawer--right {
  top: 0;
  right: 0;
  transform: translateX(100%);
}
.drawer--top {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(-100%);
}
.drawer--bottom {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(100%);
}
.drawer--open--left,
.drawer--open--right {
  transform: translateX(0);
}
.drawer--open--top,
.drawer--open--bottom {
  transform: translateX(0);
}
/*
this class only applies to mobile drawers that open from the right or left,
because we need to replace the left/right controls with controls that are inside the drawer
*/
.drawer--vertical--mobile {
  .drawer-content {
    padding: 48px var(--space-l);
  }
  .mobile-navigation-button {
    .narmi-icon-x,
    .narmi-icon-chevron-left,
    .narmi-icon-chevron-right {
      position: absolute;
      top: var(--space-s);
    }
    .narmi-icon-x {
      right: var(--space-s);
    }
    .narmi-icon-chevron-left {
      left: var(--space-s);
    }
    .narmi-icon-chevron-right {
      left: 45px;
    }
  }
}

/*
  Remove padding from the content of the drawer when set to none,
  but retain the padding for the controls at the top of the drawer.
*/
.padding--all--none.drawer--vertical--mobile {
  padding: 0;
  padding-top: 48px;
}

.backdrop {
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition:
    opacity var(--transition-speed-slow) ease,
    visibility var(--transition-speed-slow) ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 0;
}
.backdrop--open {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.navigation {
  background: transparent;
  transition: transform var(--transition-speed-medium) ease;
  z-index: 1;
}
.navigation--open--right {
  transform: translateX(-132px);
}
.navigation--open--left {
  transform: translateX(95%);
}
.navigation--open--top {
  transform: translateY(95%);
}
.navigation--open--bottom {
  transform: translateY(-132px);
}

.navigation-container--top,
.navigation-container--bottom {
  display: flex;
  flex-direction: row-reverse;
}

/* Buttons */
.navigation-button {
  color: var(--color-lightGrey);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #333333;
  cursor: pointer;
}
.navigation-button:hover {
  background-color: var(--theme-primary);
}
.navigation-button--disabled {
  color: rgba(217, 217, 217, 0.2);
  background: #4c4c4c;
  cursor: not-allowed;
}
.navigation-button--disabled:hover {
  color: rgba(217, 217, 217, 0.2);
  background: #4c4c4c;
}
.navigation-button--left,
.navigation-button--right {
  margin-bottom: var(--space-l);
}
.navigation-button--bottom,
.navigation-button--top {
  margin-right: var(--space-l);
}
