.nds-progressbar {
  border: 1px solid var(--theme-primary);
  border-radius: var(--border-radius-default);
  box-sizing: border-box;
  position: relative;
}

.nds-progressbar svg {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.nds-progressbar line {
  stroke: var(--theme-primary);
  transition: all 0.5s ease-in-out;
}
