/**
 * Do not edit directly
 * Generated on Wed, 08 Jan 2025 17:06:04 GMT
 */

:root {
  --rgb-blueGrey: 244, 246, 250;
  --rgb-cloudGrey: 233, 233, 233;
  --rgb-neutralGrey: 249, 249, 249;
  --rgb-smokeGrey: 243, 243, 243;
  --rgb-snowGrey: 251, 251, 251;
  --rgb-scrimLight: 255, 255, 255;
  --rgb-scrimDark: 80, 80, 80;
  --rgb-moss: 0, 101, 83;
  --rgb-pine: 26, 67, 56;
  --rgb-cove: 0, 92, 178;
  --rgb-azul: 0, 62, 160;
  --rgb-pistachio: 181, 233, 149;
  --rgb-cactus: 127, 188, 91;
  --rgb-sand: 220, 193, 162;
  --rgb-black: 51, 51, 51;
  --rgb-grey: 76, 76, 76;
  --rgb-mediumGrey: 140, 140, 140;
  --rgb-lightGrey: 217, 217, 217;
  --rgb-white: 255, 255, 255;
  --rgb-successDark: 55, 179, 116;
  --rgb-successLight: 227, 250, 231;
  --rgb-warnDark: 234, 195, 72;
  --rgb-warnLight: 254, 248, 227;
  --rgb-infoDark: 25, 82, 116;
  --rgb-infoLight: 232, 238, 241;
  --rgb-errorDark: 217, 59, 59;
  --rgb-errorLight: 253, 241, 241;
  --theme-rgb-primary: 26, 67, 56;
  --theme-rgb-secondary: 0, 101, 83;
  --theme-rgb-tertiary: 127, 188, 91;
  --theme-rgb-link: 0, 101, 83;
}
