@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,400;0,600;0,700;1,200;1,400;1,600;1,700&display=swap");

@font-face {
  font-family: "Narmi Matiere";
  src: url("fonts/narmi-matiere-bold-webfont.woff2")
      format("woff2"),
    url("fonts/narmi-matiere-bold-webfont.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
}
