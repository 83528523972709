// input style shared between variants
.nds-radiobuttons input {
  position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// base option styles
.nds-radiobuttons-option {
  display: block;
  position: relative;
  margin-bottom: var(--space-s);
  cursor: pointer;
  font-size: var(--font-size-default);
  &:last-child {
    margin-bottom: 0;
  }

  .nds-radiobutton-details {
    max-height: 0;
    margin-top: 0;
    overflow: hidden;

    &.nds-radiobutton-details--checked {
      margin-top: var(--space-xs);
      max-height: rem(300px);
    }
  }
}

// normal variant of radiobuttons
.nds-radiobuttons--normal {
  display: inline-block;

  .nds-radiobuttons-option {
    padding-left: rem(28px);

    .nds-radio {
      position: absolute;
      top: 0;
      left: 0;
      height: rem(20px);
      width: rem(20px);
      background-color: var(--color-white);
      border: 1px solid var(--color-lightGrey);
      border-radius: 50%;

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 50%;
        left: 50%;
        width: rem(12px);
        height: rem(12px);
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: var(--theme-primary);
      }
    }

    &:hover .nds-radio,
    &.nds-radiobuttons-option--focused .nds-radio,
    &.nds-radiobuttons-option--checked .nds-radio {
      border: 1px solid var(--theme-primary);
    }

    &.nds-radiobuttons-option--error .nds-radio {
      border-color: var(--color-errorDark);
    }

    &.nds-radiobuttons-option--checked .nds-radio {
      background-color: transparent;

      &:after {
        display: block;
      }
    }
  }
}

// row-start variant of radiobuttons
.nds-radiobuttons--row-start {
  display: flex;
  gap: var(--space-m);

  .nds-radiobuttons-option {
    padding-left: rem(28px);

    .nds-radio {
      position: absolute;
      top: 0;
      left: 0;
      height: rem(20px);
      width: rem(20px);
      background-color: var(--color-white);
      border: 1px solid var(--color-lightGrey);
      border-radius: 50%;

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 50%;
        left: 50%;
        width: rem(12px);
        height: rem(12px);
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: var(--theme-primary);
      }
    }

    &:hover .nds-radio,
    &.nds-radiobuttons-option--focused .nds-radio,
    &.nds-radiobuttons-option--checked .nds-radio {
      border: 1px solid var(--theme-primary);
    }

    &.nds-radiobuttons-option--error .nds-radio {
      border-color: var(--color-errorDark);
    }

    &.nds-radiobuttons-option--checked .nds-radio {
      background-color: transparent;

      &:after {
        display: block;
      }
    }
  }
}

// row variant of radiobuttons
.nds-radiobuttons--row {
  display: flex;
  justify-content: space-between;

  .nds-radiobuttons-option {
    padding-left: rem(28px);

    .nds-radio {
      position: absolute;
      top: 0;
      left: 0;
      height: rem(20px);
      width: rem(20px);
      background-color: var(--color-white);
      border: 1px solid var(--color-lightGrey);
      border-radius: 50%;

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 50%;
        left: 50%;
        width: rem(12px);
        height: rem(12px);
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: var(--theme-primary);
      }
    }

    &:hover .nds-radio,
    &.nds-radiobuttons-option--focused .nds-radio,
    &.nds-radiobuttons-option--checked .nds-radio {
      border: 1px solid var(--theme-primary);
    }

    &.nds-radiobuttons-option--error .nds-radio {
      border-color: var(--color-errorDark);
    }

    &.nds-radiobuttons-option--checked .nds-radio {
      background-color: transparent;

      &:after {
        display: block;
      }
    }
  }
}

// checkmark style variant
.nds-radiobuttons--checkmark {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  .nds-radiobuttons-label-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: var(--space-xs);
  }
  .nds-radio {
    visibility: hidden;
    font-weight: var(--font-weight-bold);
    flex-shrink: 1;
    &:before {
      font-size: var(--font-size-l);
      font-weight: var(--font-weight-bold);
    }
  }
  .nds-radiobuttons-option {
    margin: 0;
    &:hover .nds-radio,
    &.nds-radiobuttons-option--focused .nds-radio,
    &.nds-radiobuttons-option--checked .nds-radio {
      visibility: visible;
    }
    &:hover .nds-radio,
    &.nds-radiobuttons-option--focused .nds-radio {
      color: var(--color-lightGrey);
    }
    &.nds-radiobuttons-option--checked {
      color: var(--theme-primary);
      .nds-radio {
        &:before {
          color: var(--theme-primary);
        }
      }
    }
  }
}

// card style radiobuttons variant
.nds-radiobuttons--card {
  display: block;

  .nds-radiobutton-details {
    max-height: 0;
    opacity: 0;
    padding-right: 60px;
    line-height: 0;
    transition:
      max-height 0.3s ease,
      opacity 0.15s ease,
      line-height 0.15s ease,
      margin-top 0.2s ease;
    overflow: hidden;

    &.nds-radiobutton-details--checked {
      line-height: var(--font-lineHeight-default);
      max-height: rem(300px);
      opacity: 1;
      margin-top: var(--space-s);
    }
  }

  .nds-radiobuttons-option {
    position: relative;
    background-color: var(--color-white);

    .nds-radiobuttons-label-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: var(--theme-primary);
      font-weight: var(--font-weight-bold) !important;
    }

    .narmi-icon-check {
      display: none;
    }

    &:hover,
    &.nds-radiobuttons-option--focused,
    &.nds-radiobuttons-option--checked {
      border-color: var(--theme-primary);
    }

    &.nds-radiobuttons-option--error {
      border-color: var(--color-errorDark);
    }

    &.nds-radiobuttons-option--checked {
      background-color: RGBA(var(--theme-rgb-primary), var(--alpha-5));
      .nds-radiobuttons-label-container {
        background-color: transparent;
      }
    }

    &.nds-radiobuttons-option--checked .narmi-icon-check {
      margin-left: var(--space-default);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: rem(20px); // the circle should scale with the icon
      height: rem(20px);
      border-radius: 100%;
      background-color: var(--theme-primary);
      color: var(--color-white);
      font-size: var(--font-size-s);
      font-weight: var(--font-weight-default);
    }
  }
}

// "input-card" variant
.nds-radiobuttons--input-card {
  display: block;

  .nds-radiobutton-details {
    color: var(--font-color-primary);
  }

  .nds-radiobuttons-option {
    padding-left: rem(52px) !important;

    .nds-radio {
      position: absolute;
      top: rem(20px);
      left: rem(20px);
      height: rem(20px);
      width: rem(20px);
      background-color: var(--color-white);
      border: 1px solid var(--color-lightGrey);
      border-radius: 50%;

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 50%;
        left: 50%;
        width: rem(12px);
        height: rem(12px);
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: var(--theme-primary);
      }
    }

    &:hover .nds-radio,
    &.nds-radiobuttons-option--focused .nds-radio,
    &.nds-radiobuttons-option--checked .nds-radio {
      border: 1px solid var(--theme-primary);
    }

    &.nds-radiobuttons-option--error .nds-radio {
      border-color: var(--color-errorDark);
    }

    &.nds-radiobuttons-option--checked .nds-radio {
      background-color: transparent;

      &:after {
        display: block;
      }
    }
  }

  .nds-radiobuttons-option {
    .nds-radiobuttons-label-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: var(--theme-primary);
      font-weight: var(--font-weight-bold) !important;
      font-size: var(--font-size-default);
    }

    &:hover,
    &.nds-radiobuttons-option--focused,
    &.nds-radiobuttons-option--checked {
      border-color: var(--theme-primary);
    }

    &.nds-radiobuttons-option--error {
      border-color: var(--color-errorDark);
    }
  }
}
